import { CurrenciesMoves, CurrenciesMovesHeaders, CurrenciesExposure, CurrenciesExposureHeaders } from "@/models/currencies";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";

export const getCurrenciesMoves = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id } = params;

    const url = next_page_url || `/API/v2/${universe_id}/currencies/list/moves?dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<CurrenciesMoves, CurrenciesMovesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getCurrenciesExposure = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id } = params;

    const url = next_page_url || `/API/v2/${universe_id}/currencies/list/exposure?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<CurrenciesExposure, CurrenciesExposureHeaders>>(url, {
            params: {
                ...defaultParams,
            },
            signal: params.signal,
        });

        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getCurrenciesOverview = async () => {
    try {
        const res = await axios.get("API/v2/currencies/list/overview");

        return res.data;
    } catch (err) {
        throw err;
    }
};
