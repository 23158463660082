import React, { useContext, useEffect } from "react";
import styles from "./MarketsOverview.module.scss";
import { classNames } from "@/lib/utils";
import { useRecoilValue } from "recoil";
import MorningNote from "@/components/morning-note/MorningNote";
import Module from "@/components/module/Module";
import { regionState, themeState, userDataState } from "@/lib/store";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { Context } from "@/lib/Context";
import StockIndexes from "@/components/stock-indexes/StockIndexes";
import Yields from "@/components/yields/Yields";
import MarketsLiveCharts from "@/components/markets-live-charts/MarketsLiveCharts";
import { DataGridNews } from "@/components/data-grid-news/DataGridNews";
import ContentLayout from "@/layouts/content/ContentLayout";
import { Box } from "@mui/material";
import { useDebounce } from "react-use";
import useOpenSubscriptionForm from "src/hooks/useOpenSubscriptionForm";

const newsOptions = { min_impact: 7 };

const MarketsOverview = () => {
    const { openSubscriptionForm } = useOpenSubscriptionForm();
    const theme = useRecoilValue(themeState);
    const userData = useRecoilValue(userDataState);

    const region = useRecoilValue(regionState);

    const { query } = useRouter();

    useDebounce(
        () => {
            if (query.message === "welcome" && userData?.first_name) {
                openSubscriptionForm();
                toast(`${userData.first_name}, welcome to ORTEX!`, {
                    type: "success",
                });
            }
            if (query.hasOwnProperty("promo") && userData?.first_name) {
                query.promo ? sessionStorage.setItem("promoCode", query.promo as string) : sessionStorage.setItem("promoCode", "true");
            }
        },
        1000,
        [query.message, query.promo, userData?.first_name]
    );

    return (
        <ContentLayout title={"Markets Overview"} help={{ moduleId: "market_overview", modulePage: "V2_market_overview" }}>
            <Box className={classNames(styles[theme], styles.MarketsOverview)}>
                <Box className={styles.content}>
                    <Module title="ORTEX Market Commentary" help={{ moduleId: "morning_note", modulePage: "V2_smarkets_overview" }} noBackground noPadding={{ type: "all", source: "body" }}>
                        <MorningNote />
                    </Module>
                    <Box className={styles["custom-div"]}>
                        <Module title={`Stock Indexes`} help={{ moduleId: "sector_moves", modulePage: "V2_smarkets_overview" }}>
                            <StockIndexes />
                        </Module>
                    </Box>

                    <Module title="News" help={{ moduleId: "news", modulePage: "V2_smarkets_overview" }} link="/news" noPadding={{ source: "body", type: "all" }}>
                        <DataGridNews id={0} options={{ ...newsOptions, type: "universe" }} />
                    </Module>

                    <Module title="Currencies" help={{ moduleId: "currencies", modulePage: "V2_smarkets_overview" }}>
                        <MarketsLiveCharts type="currencies" />
                    </Module>

                    <Module title="Commodities" help={{ moduleId: "commodities", modulePage: "V2_smarkets_overview" }}>
                        <MarketsLiveCharts type="commodities" />
                    </Module>

                    <Module noPadding={{ type: "all", source: "body" }} title="Yields" help={{ moduleId: "yields", modulePage: "V2_smarkets_overview" }}>
                        <Yields />
                    </Module>
                </Box>
            </Box>
        </ContentLayout>
    );
};

export default MarketsOverview;
