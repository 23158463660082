import { useContext, useEffect, useState } from "react";
import { Box, Card, CardContent, Chip, Typography, useMediaQuery } from "@mui/material";
import { getYields } from "@/controllers/yields";
import ReactCountryFlag from "react-country-flag";
import { useRecoilValue } from "recoil";
import { dividerDarkState, greyState, textColorState, themeState, dividerState } from "@/lib/store";
import styles from "./Yields.module.scss";
import { classNames, nFormatter } from "@/lib/utils";
import Color from "@/lib/Color";
import Divider from "@mui/material/Divider";
import { ModuleContext } from "@/lib/Context";

const DEFAULT_FLAGS = ["US", "JP", "GB", "DE", "CN", "CA", "BR", "AU"];

const Yields = () => {
    const { obfuscated, setObfuscated } = useContext(ModuleContext);
    const theme = useRecoilValue(themeState);
    const textColor = useRecoilValue(textColorState);
    const dividerDark = useRecoilValue(dividerDarkState);
    const grey = useRecoilValue(greyState);
    const chipGrey = new Color(grey).brighten(0.35).get() as string;
    const dividerColor = useRecoilValue(dividerState);

    const [rows, setRows] = useState<any[]>([]);
    const isLargeScreen = useMediaQuery("(min-width: 2000px)");

    useEffect(() => {
        getYields().then((res) => {
            setObfuscated(res.obfuscated);
            setRows(res.rows);
        });
    }, []);

    return (
        <Box className={classNames(styles.yields, obfuscated ? "obfuscated" : null)}>
            <Box className={styles.headers}>
                <Box>
                    <Box>
                        <Chip
                            sx={{ height: "fit-content", textTransform: "initial", backgroundColor: theme === "dark" ? dividerDark : chipGrey, color: theme === "dark" ? textColor : textColor }}
                            label={<Typography variant="captionSessionChip">5Y</Typography>}
                            size="small"
                            variant="outlined"
                        />
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: dividerColor }} />
                    <Box>
                        <Chip
                            sx={{ height: "fit-content", textTransform: "initial", backgroundColor: theme === "dark" ? dividerDark : chipGrey, color: theme === "dark" ? textColor : textColor }}
                            label={<Typography variant="captionSessionChip">10Y</Typography>}
                            size="small"
                            variant="outlined"
                        />
                    </Box>
                </Box>
            </Box>

            {rows.map((row, i) => (
                <Card
                    sx={{
                        height: `calc(${isLargeScreen ? "85%" : "75%"} / ${rows.length})`,
                        backgroundColor: "transparent",
                        borderBottom: theme === "dark" ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                        borderColor: theme === "dark" ? `rgb(62, 62, 62)` : "none",
                    }}
                    elevation={2}
                    className={styles.yield}
                    key={row?.id}
                >
                    <CardContent className={styles.yieldCardContent}>
                        <Box className={styles.country}>
                            <ReactCountryFlag
                                countryCode={obfuscated ? DEFAULT_FLAGS[i] : row.name?.[1]}
                                aria-label={row.name?.[1]}
                                svg
                                style={{
                                    fontSize: "1.5em",
                                    marginRight: "8px",
                                }}
                            />
                            <Typography variant="body2">{row.name?.[0]}</Typography>
                        </Box>
                        <Box className={styles.values}>
                            <Box>
                                <Typography variant="body2">
                                    {nFormatter(row.fiveYears?.[0], 2)}
                                    {row.fiveYears?.[0] && "%"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">
                                    {nFormatter(row.tenYears?.[0], 2)}
                                    {row.tenYears?.[0] && "%"}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default Yields;
