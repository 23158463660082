import { CommoditiesExposure, CommoditiesExposureHeaders, CommoditiesMoves, CommoditiesMovesHeaders } from "@/models/commodities";
import { List, ListQueryParams } from "@/models/table";
import axios from "axios";

export const getCommoditiesMoves = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id } = params;

    const url = next_page_url || `/API/v2/${universe_id}/commodities/list/moves?dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<CommoditiesMoves, CommoditiesMovesHeaders>>(url, {
            params: {
                ...defaultParams,
            },
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getCommoditiesExposure = async (params: ListQueryParams) => {
    const { page_size, page, filter_options, next_page_url, universe_id } = params;

    const url = next_page_url || `/API/v2/${universe_id}/commodities/list/exposure?&dgformat`;
    const defaultParams = next_page_url ? {} : { page_size, page, ...filter_options };

    try {
        const res = await axios.get<List<CommoditiesExposure, CommoditiesExposureHeaders>>(url, {
            params: {
                ...defaultParams,
            },
        });
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getCommoditiesOverview = async () => {
    try {
        const res = await axios.get("API/v2/commodities/list/overview");
        return res.data;
    } catch (err) {
        throw err;
    }
};
