import axios from "axios";
import { Yield } from "@/models/yields";

export const getYields = async () => {
    try {
        const res = await axios.get<Yield>(`/interface/api/yield/gov_bond/list`);

        return res.data;
    } catch (err) {
        throw err;
    }
};
